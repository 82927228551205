<template>
  <el-dialog :title="title" width="60%" class="dialogForm" :close-on-click-modal="false" :visible.sync="visible"
             v-if="visible">
    <el-form size="small" :model="personalForm" ref="personalForm" label-width="100px"
             @keyup.enter.native="doSubmit()" v-loading="loading" :disabled="method==='view'"
             @submit.native.prevent>
      <el-form-item label="头像：" prop="image">
        <HeadUpload :image="personalForm.image" @getImgList="getImgList"/>
      </el-form-item>
      <el-form-item label="姓名：" prop="name"
                    :rules="[{ required: true, whitespace: true, message: '请输入姓名', trigger: 'blur' }]">
        <el-input v-model.trim="personalForm.name" placeholder="请输入姓名（必填项）" maxlength="20"></el-input>
      </el-form-item>
      <el-form-item label="手机号：" prop="mobilePhone"
                    :rules="[{ required: true, message: '请输入手机号', trigger: 'blur' },{validator:validator.isMobile, trigger:'blur'}]">
        <el-input v-model.number="personalForm.mobilePhone" autocomplete="new-password"
                  placeholder="如修改手机号，请输入手机号码" maxlength="11" :readonly="readonlyFlag"
                  @focus="readonlyFlag = false"></el-input>
      </el-form-item>
      <el-form-item v-if="method == 'add'" label="密码：" prop="password"
                    :rules="[{ required: true, message: '请输入密码', trigger: 'blur' },{validator:validator.isPwd, trigger:'blur'}]">
        <el-input v-model.trim="personalForm.password" autocomplete="new-password"
                  :type="passwordShow ? 'password' : 'text'"
                  placeholder="请输入密码（8~16位数字、特殊字符、大写字母、小写字母组合）" maxlength="16">
          <template slot="suffix">
            <div v-show="passwordShow" @click="passwordShow = !passwordShow"
                 class="iconfont icon-eye-close passwordShowIcon"></div>
            <div v-show="!passwordShow" @click="passwordShow = !passwordShow"
                 class="iconfont icon-browse passwordShowIcon"></div>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item label="性别：" prop="sex">
        <el-radio-group v-model="personalForm.sex">
          <el-radio label="2">保密</el-radio>
          <el-radio label="0">男</el-radio>
          <el-radio label="1">女</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="邮箱：" prop="email" :rules="[{validator:validator.isEmail, trigger:'blur'}]">
        <el-input v-model="personalForm.email" placeholder="邮箱（xxxxxxx@xxx.xxx）" maxlength="30"
                  auto-complete="new-password" clearable></el-input>
      </el-form-item>
      <el-form-item label="电话：" prop="telPhone"
                    :rules="[{validator:validator.isPhone, trigger:'blur'}]">
        <el-input v-model="personalForm.telPhone" placeholder="请输入电话（xxxx-xxxxxxx）"
                  maxlength="13" auto-complete="new-password" clearable></el-input>
      </el-form-item>
      <el-form-item label="权限级别：" prop="permissionLevel">
        <el-select v-model="personalForm.permissionLevel" placeholder="请选择" class="w100i" clearable>
          <el-option label="一级权限" value="0"></el-option>
          <el-option label="二级权限" value="1"></el-option>
          <el-option label="三级权限" value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所属机构：" prop="libraryId"
                    :rules="[{required: true, message: '所属机构不能为空', trigger: 'change'}]">
        <el-select v-model="personalForm.libraryId" filterable placeholder="请选择" class="w100i"
                   @change="selLibrary">
          <el-option v-for="item in libraryOpt" :key="item.id" :label="item.departmentName"
                     :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所在部门：" prop="department"
                    :rules="[{required: true, message: '所在部门不能为空', trigger: 'change'}]">
        <SelectTree
            ref="menuParentTree"
            :props="{
                            value: 'id',             // ID字段名
                            label: 'departmentName',         // 显示名称
                            children: 'children'    // 子级字段名
                        }"
            :data="departmentOpt"
            :value="personalForm.department"
            :clearable="false"
            :accordion="true"
            @getValue="(value) => {personalForm.department=value}"/>
      </el-form-item>
      <el-form-item label="角色：" prop="roleNames" v-if="method != 'add'">
        <el-input v-model="personalForm.roleNames" disabled></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="text_center">
      <el-button size="small" @click="visible = false">{{ method == 'view' ? '关闭' : '取消' }}</el-button>
      <el-button size="small" type="primary" v-if="method != 'view'" @click="doSubmit()"
                 v-noMoreClick>保存
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import HeadUpload from "@/components/headUpload"
import SelectTree from '@/components/treeSelect/treeSelect.vue'

export default {
  data() {
    return {
      visible: false,
      loading: false,
      title: '',
      method: '',
      // 个人信息
      personalForm: {
        id: '',
        image: '',
        name: '',
        sex: '2',
        mobilePhone: '',
        password: '',
        email: '',
        telPhone: '',
        libraryId: '',
        department: '',
        permissionLevel: '',
        roleNames: '',
      },
      libraryOpt: [],
      departmentOpt: [],
      readonlyFlag: true,
      passwordShow: true,
    }
  },
  components: {
    HeadUpload, SelectTree
  },
  methods: {
    // 初始化数据
    init(method, row) {
      this.personalForm = {
        id: '',
        image: '',
        name: '',
        sex: '2',
        mobilePhone: '',
        password: '',
        email: '',
        telPhone: '',
        libraryId: '',
        department: '',
        permissionLevel: '',
        roleNames: '',
      }
      this.passwordShow = true
      this.libraryOpt = []
      this.departmentOpt = []
      this.getSysLibrary(row)
      this.method = method
      if (method === 'add') {
        this.title = `新增用户`
      } else if (method === 'edit') {
        this.title = '修改用户'
      } else if (method === 'view') {
        this.title = '查看用户'
      }
      this.visible = true
      this.$nextTick(() => {
        this.$refs.personalForm.resetFields();
        this.personalForm.password = ''
        this.$set(this.personalForm, 'password', '')
      })
    },
    // 选择机构
    selLibrary(val, row) {
      this.personalForm.department = ''
      let arr = JSON.parse(JSON.stringify(this.libraryOpt))
      this.departmentOpt = arr.filter(item => item.id == val)[0].children
      this.$nextTick(() => {
        if (this.method != 'add' && row) {
          this.recover(this.personalForm, row);
          this.$nextTick(() => {
            this.$refs['personalForm'].clearValidate()
          })
        }
      })
    },
    // 获取机构
    getSysLibrary(row) {
      this.$axios(this.api.user.getSysDepartmentLibrary).then(res => {
        if (res.status) {
          this.libraryOpt = res.data
          this.recursion(this.libraryOpt)
          this.$nextTick(() => {
            if (this.method != 'add') {
              if (row.libraryId) {
                this.selLibrary(row.libraryId, row)
              } else {
                this.recover(this.personalForm, row);
                this.$nextTick(() => {
                  this.$refs['personalForm'].clearValidate()
                })
              }
            }
          })
        }
      })
    },
    recursion(list) {
      list.map((item, index) => {
        if (item.enabled == 1) {
          list.splice(index, 1)
        }
        if (item.children.length) {
          this.recursion(item.children)
        }
      })
    },
    // 表单提交
    doSubmit() {
      this.$refs['personalForm'].validate((valid) => {
        if (valid) {
          this.loading = true
          if (this.method === 'add') {  // 新增
            this.$axios(this.api.user.sysuserSave, this.personalForm, 'put').then(data => {
              this.loading = false
              if (data && data.status) {
                this.$message.success(data.msg)
                this.visible = false
                this.$emit('refreshDataList')
              } else {
                this.$message.error(data.msg)
              }
            })
          } else if (this.method === 'edit') {  // 修改
            let p = JSON.parse(JSON.stringify(this.personalForm))
            p.type = 2
            delete p.password;
            this.$axios(this.api.user.sysuserUpdateById, p, 'post').then(data => {
              this.loading = false
              if (data && data.status) {
                if (JSON.parse(sessionStorage.getItem('userInfo')).id == this.personalForm.id) {
                  this.$store.commit('user/updateUser', data.data)
                }
                this.visible = false
                this.$message.success(data.msg)
                this.$emit('refreshDataList')
              } else {
                this.$message.error(data.msg)
              }
            })
          }
        }
      })
    },
    // 获取用户头像
    getImgList(data) {
      this.personalForm.image = data
    },
  }
}
</script>
